<template>
  <div class="assessment">
    <div style="margin-bottom: 15px">
      <el-radio-group @change="tabChange" v-model="tableType">
        <el-radio-button v-if="!publicPeopleVisible" :label="1">日常考核管理</el-radio-button>
        <el-radio-button :label="6">日常考核评分</el-radio-button>
        <el-radio-button v-if="!publicPeopleVisible" :label="2">环境文化评比管理</el-radio-button>
        <el-radio-button :label="3">环境文化评比评分</el-radio-button>
        <el-radio-button v-if="!publicPeopleVisible" :label="4">班级文化汇总管理</el-radio-button>
        <el-radio-button :label="5">班级文化汇总评分</el-radio-button>
      </el-radio-group>
    </div>

    <div v-if="tableType == 1">
      <div class="flex-box">
        <div>
          <div v-if="!publicPeopleVisible">
            <el-button
              type="success"
              @click="addDailyAssessment"
              icon="el-icon-plus"
              >新增</el-button
            >
          </div>
        </div>
        <div>
          <el-date-picker v-model="query1.queryTime"
              type="daterange"
              class="header-query-frame"
              range-separator="至"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        
          <el-select @clear="loadDailyAssessment(1,type)" v-if="!publicPeopleVisible" class="picker" v-model="query1.teacher" clearable placeholder="请选择教师">
            <el-option
              v-for="item in allTeacherList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <!-- 查询状态 -->
          <el-select @clear="loadDailyAssessment(1,type)" clearable class="marginLeft" v-model="query1.status" placeholder="请选择状态">
            <el-option
              v-for="item in statusList1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button @click="loadDailyAssessment(1,type)" class="marginLeft" type="primary">查 询</el-button>
        </div>
      </div>
      <!-- 表格 -->
      <el-table :data="dailyAssessmentList">
        <el-table-column
          label="名称"
          prop="name"
          key="1"
          align="center"
        ></el-table-column>
        <el-table-column
          label="学期"
          key="3"
          prop="semester"
          align="center"
        ></el-table-column>
        <el-table-column
          label="值日教师"
          prop="mname"
          key="4"
          align="center"
        ></el-table-column>
        <el-table-column
          label="评分日期"
          prop="check_time"
          key="5"
          align="center"
        ></el-table-column>
        <el-table-column
          v-if="publicPeopleVisible"
          label="发布人"
          key="7"
          prop="operid"
          align="center"
        ></el-table-column>
        <el-table-column
          label="状态"
          key="8"
          prop="statusStr"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" :width="!publicPeopleVisible ? '300px' : '200px'" key="6" align="center">
          <template slot-scope="scope">
            <div v-if="publicPeopleVisible">
              <el-button size="mini" v-if="scope.row.status == 1" type="primary" @click="dailyAssessmentScore(scope.row)"
                >评 分</el-button
              >
              <el-button size="mini" v-if="scope.row.status == 2" type="warning" @click="dailyAssessmentScore(scope.row)"
                >查 看</el-button
              >
            </div>
            <div v-if="!publicPeopleVisible">
              <el-button size="mini" :disabled="scope.row.status == 2" type="primary" @click="editDailyAssessment(scope.row)"
                >编 辑</el-button
              >
              <el-button size="mini" :disabled="scope.row.status == 1" type="success" @click="detailDailyAssessment(scope.row)"
                >详 情</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="deleteDailyAssessment(scope.row.id)"
                >删 除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
            layout="prev,pager,next"
            background
            :page-count="page.pageCount"
            :page-size="page.currentPage"
            @current-change="loadDailyAssessment($event,type)"
      ></el-pagination>

      <!-- <el-dialog
        :title="dailyAssessmentForm.id ? '编辑日常考核' : '添加日常考核'"
        :visible.sync="dailyAssessmentDialogVisible"
        width="530px"
      >
        <div class="center">
          <el-form
            :model="dailyAssessmentForm"
            ref="dailyAssessmentFormRef"
            label-width="170px"
            :rules="dailyAssessmentRules"
          >
            <el-form-item label="名称：" prop="name">
              <el-input v-model="dailyAssessmentForm.name"></el-input>
            </el-form-item>
            <el-form-item label="值日教师：" prop="managerid">
              <el-select
                v-model="dailyAssessmentForm.managerid"
                filterable
                clearable
                default-first-option
                placeholder="请选择值日教师">
                <el-option
                  v-for="item in allTeacherList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item v-if="!dailyAssessmentForm.id" label="开始评分时间：" prop="start_time">
              <el-date-picker
                v-model="dailyAssessmentAddForm.start_time"
                type="date"
                placeholder="请选择开始评分时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item v-if="!dailyAssessmentForm.id" label="结束评分时间：" prop="end_time">
              <el-date-picker
                v-model="dailyAssessmentAddForm.end_time"
                type="date"
                placeholder="请选择结束评分时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item v-if="dailyAssessmentForm.id" label="评分时间：" prop="check_time">
              <el-date-picker
                v-model="dailyAssessmentForm.check_time"
                type="date"
                placeholder="请选择评分时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dailyAssessmentDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addDailyAssessmentConfirm">确 定</el-button>
        </span>
      </el-dialog> -->

      <el-dialog
        title="添加日常考核"
        :visible.sync="dailyAssessmentAddDialogVisible"
        width="530px"
      >
        <div class="center">
          <el-form
            :model="dailyAssessmentAddForm"
            ref="dailyAssessmentAddFormRef"
            label-width="170px"
            :rules="dailyAssessmentAddRules"
          >
            <el-form-item label="名称：" prop="name">
              <el-input v-model="dailyAssessmentAddForm.name"></el-input>
            </el-form-item>
            <el-form-item label="值日教师：" prop="managerid">
              <el-select
                v-model="dailyAssessmentAddForm.managerid"
                filterable
                clearable
                default-first-option
                placeholder="请选择值日教师">
                <el-option
                  v-for="item in allTeacherList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="开始评分时间：" prop="start_time">
              <el-date-picker
                v-model="dailyAssessmentAddForm.start_time"
                type="date"
                placeholder="请选择开始评分时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item label="结束评分时间：" prop="end_time">
              <el-date-picker
                v-model="dailyAssessmentAddForm.end_time"
                type="date"
                placeholder="请选择结束评分时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dailyAssessmentAddDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addDailyAssessmentConfirm('dailyAssessmentAddFormRef')">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="编辑日常考核"
        :visible.sync="dailyAssessmentDialogVisible"
        width="530px"
      >
        <div class="center">
          <el-form
            :model="dailyAssessmentForm"
            ref="dailyAssessmentFormRef"
            label-width="170px"
            :rules="dailyAssessmentRules"
          >
            <el-form-item label="名称：" prop="name">
              <el-input v-model="dailyAssessmentForm.name"></el-input>
            </el-form-item>
            <el-form-item label="值日教师：" prop="managerid">
              <el-select
                v-model="dailyAssessmentForm.managerid"
                filterable
                clearable
                default-first-option
                placeholder="请选择值日教师">
                <el-option
                  v-for="item in allTeacherList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="评分时间：" prop="check_time">
              <el-date-picker
                v-model="dailyAssessmentForm.check_time"
                type="date"
                placeholder="请选择评分时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dailyAssessmentDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addDailyAssessmentConfirm('dailyAssessmentFormRef')">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title=""
        :visible.sync="dailyAssessmentScoreTableDialogVisible"
        width="820px"
      >
        <div class="center form-height">
          <div class="title">竹香学校班级文化建设日常考核表</div>
          <table border="1" cellspacing="0" cellpadding="3">
            <thead>
              <tr>
                <th>班级</th>
                <th>卫生是否整洁</th>
                <th>课间纪律良好</th>
                <th>教师到岗准时</th>
                <th>家长进校有礼</th>
                <th>集会表现</th>
                <th>扣分情况</th>
                <th>备注</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in scoreList" :key="index">
                <td>{{item.cname}}</td>
                <td>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score1" :label="0">是</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score1" :label="0.1">否</el-radio>
                </td>
                <td>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score2" :label="0">是</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score2" :label="0.1">否</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score2" :label="6">全扣</el-radio>
                </td>
                <td>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score3" :label="0">是</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score3" :label="0.1">否</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score3" :label="5">全扣</el-radio>
                </td>
                <td>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score4" :label="0">是</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score4" :label="0.1">否</el-radio>
                </td>
                <td>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score5" :label="0">0</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score5" :label="0.5">0.5</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score5" :label="1">1</el-radio>
                </td>
                <td>{{item.total_score}}</td>
                <td>
                  <el-input :disabled="dailyAssessmentScoreDisabled" v-model="item.remark" placeholder=""></el-input>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <span v-if="!dailyAssessmentScoreDisabled" slot="footer" class="dialog-footer">
          <el-button @click="dailyAssessmentScoreTableDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addDailyAssessmentScoreConfirm">确 定</el-button>
        </span>

      </el-dialog>

    </div>

    <div v-if="tableType == 6">
      <div class="flex-box">
        <div>
          <div v-if="!publicPeopleVisible">
          </div>
        </div>
        <div>
          <el-date-picker v-model="query1.queryTime"
              type="daterange"
              class="header-query-frame"
              range-separator="至"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
          <!-- 请选择教师 -->
          <!-- <el-select v-if="!publicPeopleVisible" class="picker" v-model="query1.teacher" clearable placeholder="请选择教师">
            <el-option
              v-for="item in allTeacherList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select> -->
          <!-- 查询状态 -->
          <el-select @clear="loadDailyAssessment(1,type)" clearable class="marginLeft" v-model="query1.status" placeholder="请选择状态">
            <el-option
              v-for="item in statusList1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <el-button @click="loadDailyAssessment(1,type)" class="marginLeft" type="primary">查 询</el-button>
        </div>
      </div>
      <!-- 表格 -->
      <el-table :data="dailyAssessmentList">
        <el-table-column
          label="名称"
          prop="name"
          key="1"
          align="center"
        ></el-table-column>
        <el-table-column
          label="学期"
          key="3"
          prop="semester"
          align="center"
        ></el-table-column>
        <el-table-column
          label="值日教师"
          prop="mname"
          key="4"
          align="center"
        ></el-table-column>
        <el-table-column
          label="评分日期"
          prop="check_time"
          key="5"
          align="center"
        ></el-table-column>
        <el-table-column
          v-if="publicPeopleVisible"
          label="发布人"
          key="7"
          prop="operid"
          align="center"
        ></el-table-column>
        <el-table-column
          label="状态"
          key="8"
          prop="statusStr"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" :width="!publicPeopleVisible ? '300px' : '200px'" key="6" align="center">
          <template slot-scope="scope">
            <div>
              <el-button size="mini" v-if="scope.row.status == 1" type="primary" @click="dailyAssessmentScore(scope.row)"
                >评 分</el-button
              >
              <el-button size="mini" v-if="scope.row.status == 2" type="warning" @click="dailyAssessmentScore(scope.row)"
                >查 看</el-button
              >
            </div>
            
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
            layout="prev,pager,next"
            background
            :page-count="page.pageCount"
            :page-size="page.currentPage"
            @current-change="loadDailyAssessment($event,type)"
      ></el-pagination>

      <el-dialog
        title=""
        :visible.sync="dailyAssessmentScoreTableDialogVisible"
        width="820px"
      >
        <div class="center form-height">
          <div class="title">竹香学校班级文化建设日常考核表</div>
          <table border="1" cellspacing="0" cellpadding="3">
            <thead>
              <tr>
                <th>班级</th>
                <th>卫生是否整洁</th>
                <th>课间纪律良好</th>
                <th>教师到岗准时</th>
                <th>家长进校有礼</th>
                <th>集会表现</th>
                <th>扣分情况</th>
                <th>备注</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in scoreList" :key="index">
                <td>{{item.cname}}</td>
                <td>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score1" :label="0">是</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score1" :label="0.1">否</el-radio>
                </td>
                <td>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score2" :label="0">是</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score2" :label="0.1">否</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score2" :label="6">全扣</el-radio>
                </td>
                <td>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score3" :label="0">是</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score3" :label="0.1">否</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score3" :label="5">全扣</el-radio>
                </td>
                <td>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score4" :label="0">是</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score4" :label="0.1">否</el-radio>
                </td>
                <td>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score5" :label="0">0</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score5" :label="0.5">0.5</el-radio>
                  <el-radio :disabled="dailyAssessmentScoreDisabled" @change="radioChange(index)" v-model="item.score5" :label="1">1</el-radio>

                  <!-- <el-input :disabled="dailyAssessmentScoreDisabled" @input="radioChange(index)" v-model="item.score5" placeholder="0或0.5-1"></el-input> -->
                </td>
                <td>{{item.total_score}}</td>
                <td>
                  <el-input :disabled="dailyAssessmentScoreDisabled" v-model="item.remark" placeholder=""></el-input>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <span v-if="!dailyAssessmentScoreDisabled" slot="footer" class="dialog-footer">
          <el-button @click="dailyAssessmentScoreTableDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addDailyAssessmentScoreConfirm">确 定</el-button>
        </span>

      </el-dialog>
    </div>

    <div v-if="tableType == 2">
      <div class="flex-box">
        <div>
          <el-button
            type="success"
            @click="addEvaluationManagement"
            icon="el-icon-plus"
            >新增</el-button
          >
          <el-button @click="downZip('班级环境文化评比表')" type="info"
                     icon='el-icon-download'>导 出
          </el-button>
          <!-- <el-button type="danger" icon="el-icon-delete">下载</el-button> -->
        </div>
        <div>
          <!-- 月份 -->
          <el-date-picker
            value-format="M"
            v-model="query2.month"
            type="month"
            placeholder="选择月">
          </el-date-picker>
          <!-- 查询状态 -->
          <el-select @clear="queryEvaluationManagement(1)" clearable class="marginLeft" v-model="query2.status" placeholder="请选择状态">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- 查询学期 -->
          <el-select @clear="queryEvaluationManagement(1)" clearable class="marginLeft" v-model="query2.yearid" placeholder="请选择学期">
            <el-option
              v-for="item in semesterList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          
          <el-button @click="queryEvaluationManagement(1)" class="marginLeft" type="primary">查 询</el-button>
        </div>
      </div>

      <!-- 表格 -->
      <el-table :data="evaluationManagementList">
        <el-table-column
          label="名称"
          prop="name"
          key="name2"
          align="center"
        ></el-table-column>
        <el-table-column
          label="学年"
          prop="semester"
          key="semester2"
          align="center"
        ></el-table-column>
        <el-table-column
          label="月份"
          prop="month"
          key="month2"
          align="center"
        ></el-table-column>
        <el-table-column
          label="考核小组"
          prop="mnames"
          key="mnames2"
          align="center"
        ></el-table-column>
        <el-table-column
          label="评分开始时间"
          prop="begin_time"
          key="begin_time2"
          align="center"
        ></el-table-column>
        <el-table-column
          label="评分结束时间"
          prop="end_time"
          key="end_time2"
          align="center"
        ></el-table-column>
        <el-table-column
          label="状态"
          prop="statusStr"
          key="statusStr2"
          align="center"
        ></el-table-column>
        <el-table-column
          label="备注"
          prop="remark"
          key="remark2"
          align="center"
        ></el-table-column>
        
        <el-table-column label="操作" width="400px" key="operate2" align="center">
          <template slot-scope="scope">
            <div>
              <el-button size="mini" :disabled="scope.row.status !== 1" type="primary" @click="editEvaluationManagement(scope.row)"
                >编 辑</el-button
              >
              <el-button size="mini" :disabled="scope.row.status !== 2" type="warning" @click="detailEvaluationManagementMonthAverage(scope.row)"
                >详 情</el-button
              >
              <el-button size="mini" :disabled="scope.row.status === 1" type="success" @click="scoreCondition(scope.row)"
                >个人打分情况</el-button
              >
              <el-button
                 size="mini"
                type="danger"
                @click="deleteEvaluationManagement(scope.row.id)"
                >删 除</el-button
              >
            </div>

          </template>
        </el-table-column>
      </el-table>

      <el-pagination
            layout="prev,pager,next"
            background
            :page-count="page.pageCount"
            :page-size="page.currentPage"
            @current-change="queryEvaluationManagement"
      ></el-pagination>

      <el-dialog
        :title="evaluationManagementForm.id ? '编辑' : '添加'"
        :visible.sync="evaluationManagementDialogVisible"
        width="630px"
      >
        <div class="center">
          <el-form
            :model="evaluationManagementForm"
            ref="evaluationManagementFormRef"
            label-width="170px"
            :rules="evaluationManagementFormRules"
          >
            <el-form-item label="名称：" prop="name">
              <el-input v-model="evaluationManagementForm.name"></el-input>
            </el-form-item>
            <el-form-item label="月份：" prop="month">
              <el-date-picker
                @change="monthChange"
                value-format="M"
                v-model="evaluationManagementForm.month"
                type="month"
                placeholder="选择月">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="考核小组：" prop="teacherIds">
              <el-select v-model="evaluationManagementForm.teacherIds" @change="teacherGroupChange" multiple placeholder="请选择考核人">
                <el-option
                  v-for="item in allTeacherList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始评分时间：" prop="begin_time">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="evaluationManagementForm.begin_time"
                type="date"
                placeholder="选择开始时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束评分时间：" prop="end_time">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="evaluationManagementForm.end_time"
                type="date"
                placeholder="选择结束时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              <el-input type="textarea" v-model="evaluationManagementForm.remark"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="evaluationManagementDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="evaluationManagementConfirm">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title=""
        :visible.sync="detailEvaluationManagementMonthAverageDialogVisible"
        width="820px"
      >
        <div class="center form-height">
          <div class="title">竹香学校班级环境文化评比表</div>
          <table style="width:100%" border="1" cellspacing="0" cellpadding="3">
            <thead>
              <tr>
                <th >班级</th>
                <th>项目</th>
                <th>{{evaluationMonth}}</th>
              </tr>
            </thead>

            <tbody>
              <template v-for="(item) in detailEvaluationManagementMonthAverageList" >
                <tr v-for="(jtem,index2) in item.classList" :key="index2">
                  <td v-if="index2 == 0" rowspan="4">{{jtem.cname}}</td>
                  <td>{{jtem.name}}</td>
                  <td class="evaluationScoreStyle">
                    <el-input :disabled="evaluationManagementScoreDisabled" v-if="index2==0 || index2==2" v-model="jtem.score" placeholder="最多扣4分"></el-input>
                    <el-input :disabled="evaluationManagementScoreDisabled" v-if="index2==1 || index2==3" v-model="jtem.score" placeholder="最多扣6分"></el-input>
                  </td>
                </tr>
              </template>

            </tbody>
          </table>
        </div>
        <span v-if="!evaluationManagementScoreDisabled" slot="footer" class="dialog-footer">
          <el-button @click="evaluationManagementScoreDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="evaluationManagementScoreConfirm">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <div v-if="tableType == 3">
      <div class="flex-box">
        <div>
        </div>
        <div>
          <!-- 月份 -->
          <el-date-picker
            value-format="M"
            v-model="query2.month"
            type="month"
            placeholder="选择月">
          </el-date-picker>
          <!-- 查询状态 -->
          <el-select @clear="queryEvaluationChecker(1)" clearable class="marginLeft" v-model="query2.status" placeholder="请选择状态">
            <el-option
              v-for="item in statusList2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- 查询学期 -->
          <el-select @clear="queryEvaluationChecker(1)" clearable class="marginLeft" v-model="query2.yearid" placeholder="请选择学期">
            <el-option
              v-for="item in semesterList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <el-button @click="queryEvaluationChecker(1)" class="marginLeft" type="primary">查 询</el-button>
        </div>
      </div>

      <!-- 表格 -->
      <el-table :data="queryEvaluationCheckerList">
        <el-table-column
          label="名称"
          prop="name"
          key="name3"
          align="center"
        ></el-table-column>
        <el-table-column
          label="学年"
          prop="semester"
          key="semester3"
          align="center"
        ></el-table-column>
        <el-table-column
          label="月份"
          prop="month"
          key="month3"
          align="center"
        ></el-table-column>
        <el-table-column
          label="考核小组"
          prop="mnames"
          key="mnames3"
          align="center"
        ></el-table-column>
        <el-table-column
          label="评分开始时间"
          prop="begin_time"
          key="begin_time3"
          align="center"
        ></el-table-column>
        <el-table-column
          label="评分结束时间"
          prop="end_time"
          key="end_time3"
          align="center"
        ></el-table-column>
        <el-table-column
          label="状态"
          prop="check_statusStr"
          key="check_statusStr3"
          align="center"
        ></el-table-column>
        <el-table-column
          label="备注"
          prop="remark"
          key="remark3"
          align="center"
        ></el-table-column>
        
        <el-table-column label="操作" width="150px" key="operate3" align="center">
          <template slot-scope="scope">
            <div>
              <el-button size="mini" type="primary" @click="evaluationManagementDetail(scope.row)"
                >详 情</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
            layout="prev,pager,next"
            background
            :page-count="page.pageCount"
            :page-size="page.currentPage"
            @current-change="queryEvaluationManagement"
      ></el-pagination>

      <el-dialog
        title="查看"
        :visible.sync="evaluationManagementScoreDialogVisible"
        width="800px"
      >
        <div class="center">
          <div class="title">竹香学校班级环境文化评比表</div>
          <table style="width:100%" border="1" cellspacing="0" cellpadding="3">
            <thead>
              <tr>
                <th >班级</th>
                <th>项目</th>
                <th>{{evaluationMonth}}</th>
              </tr>
            </thead>

            <tbody>
              <template v-for="(item,index1) in evaluationManagementDetailList">
                <tr v-for="(jtem,index2) in item.classList" :key="index2">
                  <td v-if="index2 == 0" rowspan="4">{{jtem.cname}}</td>
                  <td>{{jtem.name}}</td>
                  <td class="evaluationScoreStyle">
                    <el-input @input="inputChange($event,4,'evaluationManagementDetailList','classList',index1,index2)" type="number" :max="4" :min="0" :disabled="evaluationManagementScoreDisabled" v-if="index2==0 || index2==2" v-model="jtem.score" placeholder="最多扣4分"></el-input>
                    <el-input @input="inputChange($event,6,'evaluationManagementDetailList','classList',index1,index2)" type="number" :max="6" :min="0" :disabled="evaluationManagementScoreDisabled" v-if="index2==1 || index2==3" v-model="jtem.score" placeholder="最多扣6分"></el-input>
                  </td>
                </tr>
              </template>

            </tbody>
          </table>
        </div>
        <span v-if="!evaluationManagementScoreDisabled" slot="footer" class="dialog-footer">
          <el-button @click="evaluationManagementScoreDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="evaluationManagementScoreConfirm">确 定</el-button>
        </span>
      </el-dialog>

    </div>

    <div v-if="tableType == 4">
      <div class="flex-box">
        <div>
          <el-button
            type="success"
            @click="addClassCultureConstruction"
            icon="el-icon-plus"
            >新增</el-button
          >
          <el-button @click="batchDelete" type="danger" icon="el-icon-delete">删除</el-button>
          <el-button @click="downZip('班级文化建设考核表')" type="info" icon="el-icon-download">导出</el-button>

          <el-dialog
            title="批量删除"
            :visible.sync="batchDeleteDialogVisible"
            width="530px"
          >
            <div class="center">
              <el-form
                :model="batchDeleteForm"
                ref="batchDeleteFormRef"
                label-width="170px"
                :rules="batchDeleteFormRules"
              >
                <el-form-item label="学期：" prop="yearid">
                  <el-select clearable v-model="batchDeleteForm.yearid" placeholder="请选择学期">
                    <el-option
                      v-for="item in semesterList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
        
              </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="batchDeleteDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="batchDeleteConfirm">确 定</el-button>
            </span>
          </el-dialog>

        </div>
        <div>
          <!-- 查询学期 -->
          <el-select @clear="queryClassCultrueConstruction" clearable v-model="query3.yearid" placeholder="请选择学期">
            <el-option
              v-for="item in semesterList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <!-- 查询状态 -->
          <el-select @clear="queryClassCultrueConstruction" clearable class="marginLeft" v-model="query3.status" placeholder="请选择状态">
            <el-option
              v-for="item in statusList3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- 查询班级 -->
          <classSelector :marginLeft="true" @change="selectClass"></classSelector>

          <el-button @click="queryClassCultrueConstruction" class="marginLeft" type="primary">查 询</el-button>
        
        </div>
      </div>

      <!-- 表格 -->
      <el-table :data="classCultureConstructionList">
        <el-table-column
          label="名称"
          prop="name"
          key="name4"
          align="center"
        ></el-table-column>
        <el-table-column
          label="学年"
          prop="semester"
          key="semester4"
          align="center"
        ></el-table-column>
        <el-table-column
          label="班级"
          prop="cname"
          key="cname4"
          align="center"
        ></el-table-column>
        <el-table-column
          label="考核小组"
          prop="mnames"
          key="mnames4"
          align="center"
        ></el-table-column>
        <el-table-column
          label="评分开始时间"
          prop="begin_time"
          key="begin_time4"
          align="center"
        ></el-table-column>
        <el-table-column
          label="评分结束时间"
          prop="end_time"
          key="end_time4"
          align="center"
        ></el-table-column>
        <el-table-column
          label="状态"
          prop="statusStr"
          key="statusStr4"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" width="270px" key="operate4" align="center">
          <template slot-scope="scope">
            <div>
              <el-button :disabled="scope.row.status !== 2" @click="lookScore(scope.row)" size="mini" type="primary"
                >详 情</el-button
              >
              <el-button size="mini" type="warning" @click="detailEvaluationManagement(scope.row)"
                >他人评分情况</el-button
              >
              <!-- <el-button
                size="mini"
                type="danger"
                @click="deleteEvaluationManagement(scope.row.id)"
                >删 除</el-button
              > -->
            </div>

          </template>
        </el-table-column>
      </el-table>

      <el-pagination
            layout="prev,pager,next"
            background
            :page-count="page.pageCount"
            :page-size="page.currentPage"
            @current-change="queryEvaluationManagement"
      ></el-pagination>

      <el-dialog
        :title="classCultureConstructionForm.id ? '编辑' : '添加'"
        :visible.sync="classCultureConstructionDialogVisible"
        width="530px"
      >
        <div class="center">
          <el-form
            :model="classCultureConstructionForm"
            ref="classCultureConstructionFormRef"
            label-width="170px"
            :rules="classCultureConstructionFormRules"
          >
            <el-form-item label="名称：" prop="name">
              <el-input v-model="classCultureConstructionForm.name"></el-input>
            </el-form-item>
            <el-form-item label="考核小组：" prop="teacherIds">
              <el-select v-model="classCultureConstructionForm.teacherIds" multiple placeholder="请选择考核人">
                <el-option
                  v-for="item in allTeacherList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始评分时间：" prop="begin_time">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="classCultureConstructionForm.begin_time"
                type="date"
                placeholder="选择开始时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束评分时间：" prop="end_time">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="classCultureConstructionForm.end_time"
                type="date"
                placeholder="选择结束时间">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="classCultureConstructionDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="classCultureConstructionConfirm">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title=""
        :visible.sync="evaluationManagementScoreDialogVisible"
        width="1190px"
      >
        <div class="center form-height">
          <div class="title">班级文化建设考核标准</div>
          <table style="width:100%" border="1" cellspacing="0" cellpadding="3">
            <thead>
              <tr>
                <th colspan="2">班级</th>
                <th style="font-size:15px;" colspan="1">{{className}}</th>
                <th>评分时间</th>
                <th style="font-size:15px;" colspan="3">{{scoreTime}}</th>
              </tr>

              <tr>
                <th colspan="1">项目</th>
                <th colspan="1">序号</th>
                <th>标准条文</th>
                <th>分值</th>
                <th>计分说明</th>
                <th>得分情况</th>
              </tr>

            </thead>

            <tbody>
              <template v-for="(item,index) in classCultureSummaryScoreDetailList" >
                <tr v-for="(jtem,index2) in item.list" :key="index2">
                  <th v-if="index2==0 && index==0" rowspan="5">{{item.project}}</th>
                  <th v-if="index2==0 && index==1" rowspan="4">{{item.project}}</th>
                  <th v-if="index2==0 && index==2" rowspan="10">{{item.project}}</th>
                  <th v-if="index2==0 && index==3" rowspan="10">{{item.project}}</th>

                  <th v-if="index==0" :key="index2">{{index2+1}}</th>
                  <th v-if="index==1" :key="index2">{{index2+1+5}}</th>
                  <th v-if="index==2" :key="index2">{{index2+1+4+5}}</th>
                  <th v-if="index==3" :key="index2">{{index2+1+4+5+10}}</th>

                  <th :key="index2">{{jtem.name}}</th>
                  <th :key="index2">{{jtem.all_score}}</th>
                  <th :key="index2">{{jtem.explain}}</th>
                  <th :key="index2" class="evaluationScoreStyle">
                    <el-input :disabled="true" class="classCultureSummaryScoreStyle" v-model="jtem.score"></el-input>
                  </th>
                </tr>
              </template>

            </tbody>
          </table>
        </div>
      </el-dialog>

    </div>

    <div v-if="tableType == 5">
      <div class="flex-box">
        <div>
        </div>
        <div>
          <!-- 查询学期 -->
          <el-select @clear="queryAllDataByCheck" clearable v-model="query3.yearid" placeholder="请选择学期">
            <el-option
              v-for="item in semesterList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <!-- 查询状态 -->
          <el-select @clear="queryAllDataByCheck" clearable class="marginLeft" v-model="query3.status" placeholder="请选择状态">
            <el-option
              v-for="item in statusList1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- 查询班级 -->
          <classSelector :marginLeft="true"></classSelector>
      
          <el-button @click="queryAllDataByCheck" class="marginLeft" type="primary">查 询</el-button>
        </div>
      </div>

      <!-- 表格 -->
      <el-table :data="classCultureSummaryScoreList">
        <el-table-column
          label="名称"
          prop="name"
          key="name5"
          align="center"
        ></el-table-column>
        <el-table-column
          label="学年"
          prop="semester"
          key="semester5"
          align="center"
        ></el-table-column>
        <el-table-column
          label="班级"
          prop="cname"
          key="cname5"
          align="center"
        ></el-table-column>
        <el-table-column
          label="考核小组"
          prop="mnames"
          key="mnames5"
          align="center"
        ></el-table-column>
        <el-table-column
          label="评分开始时间"
          prop="begin_time"
          key="begin_time5"
          align="center"
        ></el-table-column>
        <el-table-column
          label="评分结束时间"
          prop="end_time"
          key="end_time5"
          align="center"
        ></el-table-column>
        <el-table-column
          label="状态"
          prop="check_status"
          key="check_status5"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" width="200px" key="operate5" align="center">
          <template slot-scope="scope">
              <el-button size="mini" v-if="scope.row.check_status == '未完成'" type="primary" @click="classCultureSummaryScore(scope.row)"
                >评 分</el-button
              >
              <el-button size="mini" v-if="scope.row.check_status == '已完成'" type="warning" @click="classCultureSummaryScore(scope.row)"
                >查 看</el-button
              >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
            layout="prev,pager,next"
            background
            :page-count="page.pageCount"
            :page-size="page.currentPage"
            @current-change="queryEvaluationManagement"
      ></el-pagination>

      <el-dialog
        title=""
        :visible.sync="classCultureSummaryScoreDialogVisible"
        width="1190px"
      >
        <div class="center form-height">
          <div class="title">班级文化建设考核标准</div>
          <table style="width:100%" border="1" cellspacing="0" cellpadding="3">
            <thead>
              <tr>
                <th colspan="2">班级</th>
                <th colspan="1">{{className}}</th>
                <th>评分时间</th>
                <th colspan="3">{{scoreTime}}</th>
              </tr>

              <tr>
                <th colspan="1">项目</th>
                <th colspan="1">序号</th>
                <th>标准条文</th>
                <th>分值</th>
                <th>计分说明</th>
                <th>得分</th>
              </tr>
            </thead>

            <tbody>
              <template v-for="(item,index) in classCultureSummaryScoreDetailList" >
                <tr v-for="(jtem,index2) in item.list" :key="index2">
                  <th v-if="index2==0 && index==0" rowspan="5">{{item.project}}</th>
                  <th v-if="index2==0 && index==1" rowspan="4">{{item.project}}</th>
                  <th v-if="index2==0 && index==2" rowspan="10">{{item.project}}</th>
                  <th v-if="index2==0 && index==3" rowspan="10">{{item.project}}</th>

                  <th v-if="index==0" :key="index2">{{index2+1}}</th>
                  <th v-if="index==1" :key="index2">{{index2+1+5}}</th>
                  <th v-if="index==2" :key="index2">{{index2+1+4+5}}</th>
                  <th v-if="index==3" :key="index2">{{index2+1+4+5+10}}</th>

                  <th :key="index2">{{jtem.name}}</th>
                  <th :key="index2">{{jtem.all_score}}</th>
                  <th :key="index2">{{jtem.explain}}</th>

                  <th :key="index2" class="evaluationScoreStyle">
                    <el-input v-if="index !== 3" @input="inputChange($event,jtem.all_score,'classCultureSummaryScoreDetailList','list',index,index2,jtem.name)" type="number" :max="jtem.all_score" :min="0" :disabled="evaluationManagementScoreDisabled || item.project == '班级常规' || item.project == '环境文化'" class="classCultureSummaryScoreStyle" v-model="jtem.score"></el-input>
                    <el-input v-if="index === 3" type="number" :disabled="true" class="classCultureSummaryScoreStyle" v-model="jtem.score"></el-input>
                  </th>
                </tr>
              </template>

            </tbody>
          </table>
        </div>
        <span v-if="!evaluationManagementScoreDisabled" slot="footer" class="dialog-footer">
          <el-button @click="classCultureSummaryScoreDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="classCultureSummaryScoreConfirm">确 定</el-button>
        </span>
      </el-dialog>
    </div>


  </div>
</template>

<script>
import classSelector from "@/components/selectors/classSelector.vue";

export default {
  components: { classSelector },
  name: "assessment",
  data() {
    return {
      value1: true,
      evaluationManagementScoreDisabled:false,
      page: {pageCount: 1, currentPage: 0},
      value:'',
      tableType: 1,
      // 状态列表
      statusList: [
        { value: 1, label: "未完成" },
        { value: 2, label: "已完成" },
        { value: 0, label: "进行中" },
      ],
      // 状态列表
      statusList1: [
        { value: 1, label: "未完成" },
        { value: 2, label: "已完成" },
      ],
      // 状态列表
      statusList2: [
        { value: 0, label: "进行中" },
        { value: 1, label: "未完成" },
        { value: 2, label: "已完成" },
      ],
      // 状态列表
      statusList3: [
        { value: 1, label: "发布成功" },
        { value: 2, label: "已完成" },
        { value: 0, label: "进行中" },
      ],
      // 日常考核查看显示与隐藏
      dailyAssessmentCheckShow:false,
      // 日常考核查看禁用
      dailyAssessmentCheckDisabled:false,
      // 日常考核表单
      dailyAssessmentForm:{
        id:'',
        name:'',
        managerid:'',
        // start_time:'',
        // end_time:''
        check_time:''
      },
      dailyAssessmentAddForm:{
        id:'',
        name:'',
        managerid:'',
        start_time:'',
        end_time:'',
        // check_time:''
      },
      // 日常考核表单验证规则
      dailyAssessmentAddRules:{
        name:[{required:true,message:'请输入名称',trigger:'blur'}],
        managerid:[{required:true,message:'请选择教师',trigger:'blur'}],
        start_time:[{required:true,message:'请选择开始评分时间',trigger:'blur'}],
        end_time:[{required:true,message:'请选择结束评分时间',trigger:'blur'}],
        check_time:[{required:true,message:'请选择评分时间',trigger:'blur'}],
      },
      dailyAssessmentRules:{
        name:[{required:true,message:'请输入名称',trigger:'blur'}],
        managerid:[{required:true,message:'请选择教师',trigger:'blur'}],
        // start_time:[{required:true,message:'请选择开始评分时间',trigger:'blur'}],
        // end_time:[{required:true,message:'请选择结束评分时间',trigger:'blur'}],
        check_time:[{required:true,message:'请选择评分时间',trigger:'blur'}],
      },

      // 班级考核表单
      classAssessmentForm: {
        id: "",
        name: "",
        teacherIds: "",
        begin_time: "",
        end_time: "",
      },
      // 表单验证规则
      classAssessmentRules:{
        name:[{required:true,message:'请输入名称',trigger:'blur'}],
        teacherIds:[{required:true,message:'请选择值日教师',trigger:'blur'}],
        begin_time:[{required:true,message:'请选择开始时间',trigger:'blur'}],
        end_time:[{required:true,message:'请选择结束时间',trigger:'blur'}],
      },
      // 对话框显示与隐藏
      dailyAssessmentDialogVisible:false,
      dailyAssessmentAddDialogVisible:false,
      // 所有教师列表
      allTeacherList:[],
      // 班级考核列表
      dailyAssessmentList:[],
      // 模块列表
      moduleList:JSON.parse(sessionStorage.getItem('userInfo')).nav,
      // 角色类型
      role_type:JSON.parse(sessionStorage.getItem('userInfo')).role_type,
      // 角色名称
      username:JSON.parse(sessionStorage.getItem('userInfo')).username,
      // 发布人列显示与隐藏
      publicPeopleVisible:false,
      // 日常考核表显示与隐藏
      dailyAssessmentTableDialogVisible:false,
      // 班级列表
      classList:[],
      // 评分列表
      scoreList:[],
      classListLength:'',
      dailyAssessmentScoreTableDialogVisible:false,
      flag:false,
      // 日常考核评分禁用
      dailyAssessmentScoreDisabled:false,
      tab:4,
      queryClassMonthScoreByCheckDisabled:false,
      // 评分管理表单
      evaluationManagementForm:{
        id:'',
        name:'',
        month:'',
        teacherIds:'',
        begin_time:'',
        end_time:'',
        remark:'',
      },
      // 评分管理表单验证规则
      evaluationManagementFormRules:{
        name:[{required:true,message:'请输入名称',trigger:'blur'}],
        month:[{required:true,message:'请选择月份',trigger:'blur'}],
        teacherIds:[{required:true,message:'请选择考核人',trigger:'blur'}],
        begin_time:[{required:true,message:'请输入开始时间',trigger:'blur'}],
        end_time:[{required:true,message:'请输入结束时间',trigger:'blur'}],
      },
      evaluationManagementDialogVisible:false,
      // 全部教师列表
      allTeacherList:[],
      // 评分管理列表
      evaluationManagementList:[],
      // 评分管理详情平均分列表
      detailEvaluationManagementMonthAverageList:[],
      evaluationManagementScoreDialogVisible:false,
      // 班级文化平均分对话框显示与隐藏
      detailEvaluationManagementMonthAverageDialogVisible:false,
      // 评分管理详情列表
      evaluationManagementDetailList:[],
      rules:{
        score:[
          {required:true, min:0, max:4,message:'qing'}
        ]
      },
      // 评分管理考核人列表
      queryEvaluationCheckerList:[],
      // 评分月份
      evaluationMonth:'',
      // 月份id
      evaluationMonthid:'',
      // 用户id
      userid:JSON.parse(sessionStorage.getItem('userInfo')).userid,
      // 评分管理评分原数据
      evaluationManagementData:[],
      // 班级文化建设对话框显示与隐藏
      classCultureConstructionDialogVisible:false,
      // 班级文化建设列表
      classCultureConstructionList:[],
      // 班级文化建设表单
      classCultureConstructionForm:{
        name:'',
        teacherIds:'',
        begin_time:'',
        end_time:''
      },
      classCultureConstructionFormRules:{
        name:[{required:true,message:'请输入名称',trigger:'blur'}],
        teacherIds:[{required:true,message:'请选择考核人',trigger:'blur'}],
        begin_time:[{required:true,message:'请选择开始时间',trigger:'blur'}],
        end_time:[{required:true,message:'请选择结束时间',trigger:'blur'}],
      },
      // 考核人查询总数据列表
      classCultureSummaryScoreList:[],
      // 班级文化汇总评分对话框显示与隐藏
      classCultureSummaryScoreDialogVisible:false,
      // 班级文化汇总评分列表
      classCultureSummaryScoreDetailList:[],
      // 班级文化汇总评分表单对象
      classCultureSummaryScoreForm:{
        pid:'',
        people_id:'',
        classid:'',
        standard_id:'',
        score:''
      },
      // 查询
      query1:{
        queryTime:'',
        teacher:'',
        status:'',
      },
      query2:{
        status:'',
        yearid:'',
        month:''
      },
      query3:{
        yearid:'',
        status:'',
        classid:'',
      },
      // 查询
      query4:{
        queryTime:'',
        teacher:'',
        status:'',
      },
      query5:{
        status:'',
        yearid:'',
        month:''
      },
      query6:{
        yearid:'',
        status:'',
        classid:'',
      },
      // 学期列表
      semesterList:JSON.parse(sessionStorage.getItem('semesterList')),
      type:'',
      totalScore:'',
      moudleList:JSON.parse(sessionStorage.getItem('userInfo')).nav1,
      isMoudleManager:false,
      urlList:[],
      batchDeleteDialogVisible:false,
      batchDeleteForm:{
        yearid:''
      },
      batchDeleteFormRules:{
        yearid:[{required:true}]
      },
      className:'',
      scoreTime:''
    };
  },
  created() {
    // console.log(this.$route.query)

    // 教师
    this.tableType = 6
    this.publicPeopleVisible = true
    this.type = 1
    this.loadDailyAssessment(1,1)

    // 判断是否是模块管理员
    for(let i = 0; i < this.moudleList.length; i++){
      if(this.moudleList[i].id === 1201){
        // 是模块管理员
        this.isMoudleManager = true

        this.tableType = 1
        this.publicPeopleVisible = false
        this.type = 2
        this.loadDailyAssessment(1,2)
      }
      // return
    }

    let tab = Number(this.$route.query.tab)
    if(this.isMoudleManager){
      this.tableType = tab ? tab : 1
    }else{
      this.tableType = tab ? tab : 6
    }

    if(this.tableType == 1){
      this.publicPeopleVisible = false
      this.type = 2
      this.loadDailyAssessment(1,2)
    }else if(this.tableType == 2){
      this.queryEvaluationManagement(1)
    }else if(this.tableType == 3){
      this.queryEvaluationChecker(1)
    }else if(this.tableType == 4){
      this.queryClassCultrueConstruction()
    }else if(this.tableType == 5){
      this.queryAllDataByCheck()
    }else if(this.tableType == 6){
      this.publicPeopleVisible = true
      this.type = 1
      this.loadDailyAssessment(1,1)
    }
    
    // 教师列表
    this.getTeacherList()
    this.queryAllDataByCheck()

  },
  methods: {
    // 批量删除
    batchDelete(){
      this.batchDeleteDialogVisible = true
      this.$refs.batchDeleteFormRef.resetFields()
    },
    batchDeleteConfirm(){
      console.log(this.batchDeleteForm.yearid)
      console.log(this.classCultureConstructionList)
      // return
      this.$refs.batchDeleteFormRef.validate(validate=>{
        if(validate){
          this.$confirm("是否批量该学期数据?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post(`base/deleteClassStandard.do`, {
                yearid: this.batchDeleteForm.yearid,
              })
                .then((res) => {
                  let index = this.classCultureConstructionList.findIndex(item=>item.yearid === this.batchDeleteForm.yearid)
                  if(index !== -1){
                    this.$message({
                      type: "success",
                      message: "删除成功!",
                    });
                    this.batchDeleteDialogVisible = false
                    this.queryClassCultrueConstruction();
                  }else{
                    this.$message({
                      type: "info",
                      message: "没有该学期数据!",
                    });
                  }
                  
                })
                .catch((err) => {
                  this.$message.error(err.message);
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

        }
      })
      
    },
    // 查看分数
    lookScore(row){
      console.log(row)

        this.evaluationManagementScoreDialogVisible = true
        this.className = row.cname
        this.scoreTime = `${row.begin_time} 至 ${row.end_time}`
      
        console.log(row)
        // console.log(this.id,this.yearid,this.yearid,row.people_id)
        // manager_id
        this.$post(`base/queryAllClassStandardByClass.do`,{
            // pid:row.pid,
            classid:row.classid,
            yearid:row.yearid,
            // people_id:row.id
        }).then(res=>{
            console.log(res)
            let arr = []
            res.data.forEach(item=>{
                // item.score = ''
                let index = arr.findIndex(jtem=>jtem.type == item.type)
                if(index == -1){
                    if(item.type == 1){
                        let obj = {
                            type:item.type,
                            project:'班级常规',
                            list:[item]
                        }
                        arr.push(obj)
                    }else if(item.type == 2){
                        let obj = {
                            type:item.type,
                            project:'环境文化',
                            list:[item]
                        }
                        arr.push(obj)
                    }else if(item.type == 3){
                        let obj = {
                            type:item.type,
                            project:'文化活动',
                            list:[item]
                        }
                        arr.push(obj)
                    }

                }else{
                    arr[index].list.push(item)

                }

            })
            console.log(arr)
            let obj = {
                type:4,
                project:'综合',
                list:[{
                    name:'班级总分',
                    all_score:'',
                    explain:'',
                    all_score:100,
                    score:''
                }]
            }
            arr.push(obj)
            this.classCultureSummaryScoreDetailList = arr
            this.getTotalScore()

            console.log(arr)

        })

    },
    // 计算总分
    getTotalScore(){
      let total = 0
      for(let i = 0; i < this.classCultureSummaryScoreDetailList.length - 1; i++){
        this.classCultureSummaryScoreDetailList[i].list.forEach(jtem=>{
          total += Number(jtem.score)
        })
      }
      this.classCultureSummaryScoreDetailList[this.classCultureSummaryScoreDetailList.length-1].list[0].score = total
      // this.totalScore = total
    },
    // 批量下载
    downZip(title) {
      const that = this;
      let paramas = new FormData();
      let url
      let type
      if(title == '班级文化建设考核表'){
        paramas.append('yearid',this.query3.yearid)
        paramas.append('classid',this.query3.status)
        url = "/base/exportClassStandardByClass.do"
        type = "application/msword"
      }else if(title == '班级环境文化评比表'){
        if(this.query2.month != ''){
          this.query2.month = Number(this.query2.month)
        }
        paramas.append('month',this.query2.month)
        paramas.append('status',this.query2.status)
        paramas.append('yearid',this.query2.yearid)
        url = "/base/zipClassMonthAll.do"
        type = "application/zip"
      }
      this.$postFile(url, paramas)
        .then((res) => {
          console.log(res);
          const blob = new Blob([res.data], {
            type: type,
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = title;
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          console.log(error);
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    inputChange(newValue,type,name,nextList,index1,index2){
      if(name == 'classCultureSummaryScoreDetailList'){
        this.getTotalScore()
      }
      if(Number(newValue) > Number(type) || Number(newValue) < 0){
        this[name].forEach((item,i)=>{
          item[nextList].forEach((jtem,j)=>{
            if(i == index1 && j == index2){
              jtem.score = ''
            }
            
          })
        })
      }
      
    },
    // 计算总分
    getTotalScore(){
      let total = 0
      for(let i = 0; i < this.classCultureSummaryScoreDetailList.length - 1; i++){
        this.classCultureSummaryScoreDetailList[i].list.forEach(jtem=>{
          total += Number(jtem.score)
        })
      }
      this.classCultureSummaryScoreDetailList[this.classCultureSummaryScoreDetailList.length-1].list[0].score = total
    },
    
    // tab切换
    tabChange(value){
      if(value == 1){
        this.tableType = 1
        this.$router.push('?tab=1')
        this.type = 2
        this.loadDailyAssessment(1,2)
      }else if(value == 2){
        this.$router.push('?tab=2')
        this.tableType = 2
        this.queryEvaluationManagement(1)
        // 若管理员能评分,则管理员显示评分按钮
        this.scoreByManagerButtonShow()
      }else if(value == 3){
        this.$router.push('?tab=3')
        this.tableType = 3
        this.queryEvaluationChecker(1)
      }else if(value == 4){
        this.$router.push('?tab=4')
        this.tableType = 4
        this.queryClassCultrueConstruction()
      }else if(value == 5){
        this.$router.push('?tab=5')
        this.tableType = 5
        this.queryAllDataByCheck()
      }else if(value == 6){
        this.$router.push('?tab=6')
        this.tableType = 6
        this.type = 1
        this.loadDailyAssessment(1,1)
      }
    },
    // 添加班级文化建设
    addClassCultureConstruction(){
      this.classCultureConstructionDialogVisible = true
      this.$nextTick(()=>{
        this.$refs.classCultureConstructionFormRef.resetFields()
      })
      
    },
    // 班级文化建设确认
    classCultureConstructionConfirm(){
      this.$refs.classCultureConstructionFormRef.validate((validate)=>{
        if(validate){
          let data = {
            name:this.classCultureConstructionForm.name,
            teacherIds:this.classCultureConstructionForm.teacherIds.join(','),
            begin_time:this.classCultureConstructionForm.begin_time,
            end_time:this.classCultureConstructionForm.end_time
          }
          this.$post(`/base/insertClassCheckStandard.do`,data)
          .then(res=>{
            console.log(res)
            this.$message({
              type:'success',
              message:'添加成功！'
            })
            this.classCultureConstructionDialogVisible = false
            this.queryClassCultrueConstruction()
          })
          .catch(err=>{
            this.$message.error('添加失败！')
          })
        }
      })
      
    },
    // 管理员查询班级考核信息
    queryClassCultrueConstruction(){
      this.$post(`/base/queryClassStandardAll.do`,{
        yearid:this.query3.yearid,
        status:this.query3.status,
        classid:this.query3.classid
      })
      .then(res=>{
        console.log(res)
        this.classCultureConstructionList = res.data
      })
    },
    // 考核人查询总数据
    queryAllDataByCheck(){
      this.$post(`/base/queryClassStandardByCheck.do`,{
        yearid:this.query3.yearid,
        classid:this.query3.classid,
        status:this.query3.status
      })
      .then(res=>{
        this.classCultureSummaryScoreList = res.data
        console.log(res)
      })
    },
    detailEvaluationManagement(row){
      console.log(row)
      // return
      this.$router.push({
        name:'templateclassCultureSummaryScore',
        query:{
          id:row.id,
          classid:row.classid,
          yearid:row.yearid,
          people_id:row.people_id,
          className:row.cname,
          scoreTime:`${row.begin_time} 至 ${row.end_time}`
        }
      })
    },
    // 班级文化汇总评分
    classCultureSummaryScore(row){
      console.log(row)
      this.className = row.cname
      this.scoreTime = `${row.begin_time} 至 ${row.end_time}`

      if(row.check_status == '已完成'){
        this.evaluationManagementScoreDisabled = true
        this.classCultureSummaryScoreData(row)
      }else{
        this.evaluationManagementScoreDisabled = false
        if(this.isIncludedScoreTime(row.begin_time,row.end_time)){
          // 当前时间属于评分时间范围内
          this.classCultureSummaryScoreData(row)
        }else{
          this.$message({
            type:'info',
            message:'当前时间不在评分时间范围内！'
          })
        }
      }
    },
    // 班级文化汇总评分数据展示
    classCultureSummaryScoreData(row){
      this.classCultureSummaryScoreDialogVisible = true
      this.classCultureSummaryScoreForm.pid = row.id
      this.classCultureSummaryScoreForm.people_id = row.people_id
      this.classCultureSummaryScoreForm.classid = row.classid
  
      this.$post(`/base/queryClassStandardScoreByCheck.do`,{
        classid:row.classid,
        people_id:row.people_id,
        yearid:row.yearid,
        pid:row.id
      })
      .then(res=>{
        console.log(res)
        let arr = []
        res.data.forEach(item=>{
          // item.score = ''
          let index = arr.findIndex(jtem=>jtem.type == item.type)
          if(index == -1){
            if(item.type == 1){
              let obj = {
                type:item.type,
                project:'班级常规',
                list:[item]
              }
              arr.push(obj)
            }else if(item.type == 2){
              let obj = {
                type:item.type,
                project:'环境文化',
                list:[item]
              }
              arr.push(obj)
            }else if(item.type == 3){
              let obj = {
                type:item.type,
                project:'文化活动',
                list:[item]
              }
              arr.push(obj)
            }

          }else{
            arr[index].list.push(item)

          }

        })
        console.log(arr)
        let obj = {
          type:4,
          project:'综合',
          list:[{
            name:'班级总分',
            all_score:'',
            explain:'',
            all_score:100,
            score:''
          }]
        }
        arr.push(obj)
        
        this.classCultureSummaryScoreDetailList = arr
        this.getTotalScore()
        
      })
    },
    // 班级文化汇总评分提交
    classCultureSummaryScoreConfirm(){
      console.log(this.classCultureSummaryScoreDetailList)

      let data = {
        pid:this.classCultureSummaryScoreForm.pid,
        people_id:this.classCultureSummaryScoreForm.people_id,
        classid:this.classCultureSummaryScoreForm.classid,
        list:[],
      }
    
      this.classCultureSummaryScoreDetailList.forEach(item=>{
        item.list.forEach(jtem=>{
          if(jtem.type == 3){
            let obj = {
              // score:Number(jtem.score),
              score:jtem.score,
              standard_id:jtem.standard_id
            }
            data.list.push(obj)
          }
        })
      })
      console.log(data)

      for(let i = 0; i < data.list.length; i++){
        if(data.list[i].score === null || data.list[i].score === ''){
          this.$message({
            type:'info',
            message:'请先评分完毕！'
          })
          return
        }
      }

      this.$postJson(`/base/insertClassStandardScore.do`,data)
      .then(res=>{
        console.log(res)
        this.classCultureSummaryScoreDialogVisible = false
        this.queryAllDataByCheck()
      })

    },
    // 查询全部教师接口
    queryTeacher(){
      this.$post(`/media/queryAllManager.do`)
      .then(res=>{  
        console.log(res)
        this.allTeacherList = res.data
      })
    },
    // 查询评分管理
    queryEvaluationManagement(page){
      this.$post(`/base/queryClassMonthAll.do`,{
        indexNo:page,
        month:this.query2.month,
        status:this.query2.status,
        yearid:this.query2.yearid,
      })
      .then(res=>{
        console.log(res.data.rows)
        res.data.rows.forEach(item=>{
          item.month = item.month + '月'
        })
        this.evaluationManagementList = res.data.rows
      })
    },
    // 管理员评分按钮显示
    scoreByManagerButtonShow(){
  
    },
    // 添加环境文化评比管理
    addEvaluationManagement(){
      console.log(this.evaluationManagementForm)
      this.evaluationManagementDialogVisible = true
      this.evaluationManagementForm.month = ''
      this.$nextTick(()=>{
        this.$refs.evaluationManagementFormRef.resetFields()
        this.evaluationManagementForm.id = ''
        this.evaluationManagementForm.name = ''
        this.evaluationManagementForm.month = ''
        this.evaluationManagementForm.teacherIds = ''
        this.evaluationManagementForm.begin_time = ''
        this.evaluationManagementForm.end_time = ''
        this.evaluationManagementForm.remark = ''
      })
      this.queryTeacher()
    },
    teacherGroupChange(value){
      console.log(value)
    },
    monthChange(value){
      console.log(value)
      this.evaluationManagementForm.month = value
    },
    // 编辑环境文化评比管理
    editEvaluationManagement(row){
      this.evaluationManagementDialogVisible = true
      this.evaluationManagementForm.id = row.id
      this.evaluationManagementForm.name = row.name
      this.evaluationManagementForm.month = parseInt(row.month).toString()
      this.evaluationManagementForm.teacherIds = row.teacherIds.split(',').map(Number)
      this.evaluationManagementForm.begin_time = row.begin_time
      this.evaluationManagementForm.end_time = row.end_time
      this.evaluationManagementForm.remark = row.remark
    },
    // ...评分管理确认
    evaluationManagementConfirm(){
      this.$refs.evaluationManagementFormRef.validate(validate=>{
        if(validate){
          console.log(this.evaluationManagementForm.id)
          if(this.evaluationManagementForm.id){
            this.evaluationManagementForm.teacherIds = this.evaluationManagementForm.teacherIds.join(',')
            this.$post(`/base/updateClassMonth.do`,this.evaluationManagementForm)
            .then(res=>{
              this.$message.success("修改成功");
              this.evaluationManagementDialogVisible = false
              this.queryEvaluationManagement(1)
            })
            return
          }
        }
        let data = {
          name:this.evaluationManagementForm.name,
          month:this.evaluationManagementForm.month,
          teacherIds:this.evaluationManagementForm.teacherIds.join(','),
          begin_time:this.evaluationManagementForm.begin_time,
          end_time:this.evaluationManagementForm.end_time,
          remark:this.evaluationManagementForm.remark,
        }
        this.$post(`/base/insertClassMonth.do`,data)
        .then(res=>{
          this.evaluationManagementDialogVisible = false
          this.queryEvaluationManagement(1)
        })
        .catch(err=>{
          this.$message({
            type:'info',
            message:err.message
          })
        })

      })

    },
    // 删除
    deleteEvaluationManagement(id){
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$post(`/base/deleteClassMonth.do`,{id})
          .then(res=>{
            console.log(res)
            this.queryEvaluationManagement(this.page.pageCount)
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          })
          .catch(err=>{
            this.$message({
              type:'error',
              message:err.message
            })
          })
        }).catch((err) => {
          this.$message({
            type: 'info',
            message: '已取消删除！'
          });          
        });

    },
    // 判断当天时间是否属于评分时间范围内
    isIncludedScoreTime(begin_time,end_time){
      let currentTime = new Date().getTime()
      let startTime = new Date(begin_time).getTime()
      let endTime = new Date(end_time).getTime()
      console.log(currentTime)
      console.log(startTime)
      console.log(endTime)
      
      if(currentTime >= startTime && currentTime <= endTime + 86400000){
        return true
      }else{
        return false
      }
    },
    // 环境文化评比考核人查询列表
    queryEvaluationChecker(page){
      this.$post(`/base/queryClassMonthByCheck.do`,{
        indexNo:page,
        month:this.query2.month,
        status:this.query2.status,
        yearid:this.query2.yearid,
      })
      .then(res=>{
        console.log(res)
        this.queryEvaluationCheckerList = res.data.rows
      })
    },
    // 跳转到环境文化评比详情页
    evaluationManagementDetail(row){
      this.$router.push({
        name:'templateEnvironmentalCultureScore',
        query:{
          row:row,
          id:row.id,
          yearid:row.yearid,
        }
      })
    },
    // 评分点击
    evaluationManagementScore(row){
      if(row.check_status == 2){
        this.queryClassMonthScoreByCheck(row)
        this.evaluationManagementScoreDisabled = true
      }else{
        this.evaluationManagementScoreDisabled = false
        if(this.isIncludedScoreTime(row.begin_time,row.end_time)){
          // 当前时间属于评分时间范围内
          this.queryClassMonthScoreByCheck(row)
        }else{
          this.$message({
            type:'info',
            message:'当前时间不在评分时间范围内！'
          })
        }
      }
    },
    // 环境文化评比数据显示
    queryClassMonthScoreByCheck(row){
      this.evaluationManagementScoreDialogVisible = true
      this.evaluationMonth = row.month
      this.evaluationMonthid = row.id
      this.$post(`/base/queryClassMonthScoreByCheck.do`,{
        yearid:row.yearid,
        month_id:row.id,
        teacherId:this.userid
      })
      .then(res=>{
        this.evaluationManagementData = res.data
        let arr = []
        res.data.forEach((item,index1)=>{
          let i = arr.findIndex((jtem)=>jtem.classid == item.classid)
          
            if(i == -1){
              let obj = {
                classid:item.classid,
                cname:item.cname,
                classList:[]
              }
              obj.classList.push(item)
              arr.push(obj)

            }else{
              arr[i].classList.push(item)
            }  
        })

        this.evaluationManagementDetailList = arr

      })
    },
    // 提交环境评分
    evaluationManagementScoreConfirm(){
      let arr = []
      this.evaluationManagementDetailList.forEach(item=>{
        item.classList.forEach(jtem=>{
          let obj = {
            month_id:this.evaluationMonthid,
            people_id:jtem.people_id,
            classid:jtem.classid,
            score:jtem.score,
            standard_id:jtem.standard_id
          }
          arr.push(obj)
        })
      })
      
      for(let i = 0; arr.length; i++){
        if(arr[i].score == null){
          this.$message({
            type:'info',
            message:'请全部评分完毕！'
          })
          return
        }
      }

      // return
      this.$postJson(`/base/insertClassMonthScore.do`,arr)
      .then(res=>{
        console.log(res)
        this.evaluationManagementScoreDialogVisible = false
        this.queryEvaluationChecker(this.page.currentPage)
      })

    },
    // 班级文化建设月份平均分
    detailEvaluationManagementMonthAverage(row){
      this.evaluationMonth = row.month + '考核人平均分'
      this.$postJson(`/base/queryClassMonthScoreBySumMonth.do?month_id=${row.id}&yearid=${row.yearid}`)
      .then(res=>{
        let arr = []
        res.data.forEach((item,index1)=>{
          let i = arr.findIndex((jtem)=>jtem.classid == item.classid)
            if(i == -1){
              let obj = {
                classid:item.classid,
                cname:item.cname,
                classList:[]
              }
              obj.classList.push(item)
              arr.push(obj)

            }else{
              arr[i].classList.push(item)
            }
        })
        this.detailEvaluationManagementMonthAverageList = arr
        this.detailEvaluationManagementMonthAverageDialogVisible = true
        this.evaluationManagementScoreDisabled = true
      })
    },

    // 个人打分情况
    scoreCondition(row){
      this.$router.push({
        name:'personalScoring',
        query:{
          id:row.id,
          yearid:row.yearid,
          evaluationMonth:row.month
        }
      })
    },
    // 教师列表
    getTeacherList(){
      this.$post(`/media/queryAllManager.do`,{
        name:''
      })
      .then(res=>{
        this.allTeacherList = res.data
      })
    },
    // 加载日常考核
    loadDailyAssessment(page,type1){
      console.log(page,type1)
      this.$post(`/base/queryClassDailyTea.do`,{
        type:type1,
        indexNo:page,
        start_time:this.query1.queryTime[0],
        end_time:this.query1.queryTime[1],
        status:this.query1.status,
        managerid:this.query1.teacher
      })
      .then(res=>{
        console.log(res)
        res.data.rows.forEach(item=>{
          item.flag = false
        })
        this.dailyAssessmentList = res.data.rows
        this.page.currentPage = res.data.indexNO
        this.page.pageCount = res.data.pageCount
      })
    },
    // 选择班级
    selectClass(value) {
      this.query3.classid = value
    },
    // 添加日常考核
    addDailyAssessment() {  
      // this.dailyAssessmentDialogVisible = true
      // this.$nextTick(()=>{
      //   this.$refs.dailyAssessmentFormRef.resetFields()
      //   this.dailyAssessmentForm.id = ''
      // })

      this.dailyAssessmentAddDialogVisible = true
      this.$nextTick(()=>{
        this.$refs.dailyAssessmentAddFormRef.resetFields()
        this.dailyAssessmentAddFormRef.id = ''
      })
      
    },
    // 日常考核提交
    addDailyAssessmentConfirm(ref) {
      this.$refs[ref].validate(validate=>{
        if(validate){
          if(ref == 'dailyAssessmentAddFormRef'){
            let data = {
              name:this.dailyAssessmentAddForm.name,
              // check_time:this.dailyAssessmentForm.check_time,
              start_time:this.dailyAssessmentAddForm.start_time,
              end_time:this.dailyAssessmentAddForm.end_time,
              managerid:this.dailyAssessmentAddForm.managerid,
            }
            this.$post(`/base/insertClassDailyTea.do`,data)
            .then(res=>{
              this.dailyAssessmentAddDialogVisible = false
              
              this.$message({
                type:'success',
                message:'添加成功！'
              })
              this.loadDailyAssessment(1,this.type)
            })
            .catch(err=>{
              this.$message({
                type:'error',
                message:err.message
              })
            })

          }else if(ref == 'dailyAssessmentFormRef'){
            let data = {
              name:this.dailyAssessmentForm.name,
              managerid:this.dailyAssessmentForm.managerid,
              check_time:this.dailyAssessmentForm.check_time,
            }
            this.$post(`/base/updateClassDailyTea.do`,data)
              .then(res=>{
                this.dailyAssessmentDialogVisible = false
                this.$message({
                  type:'success',
                  message:'编辑成功！'
                })
                this.loadDailyAssessment(this.page.currentPage,this.type)
              })
              .catch((err) => {
                this.$message({
                  type: "info",
                  message: err.message,
                });
              });
          }
        }
      })

      return
      this.$refs.dailyAssessmentFormRef.validate(validate=>{
        if(validate){
          // 编辑提交
          if(this.dailyAssessmentForm.id){
            this.$post(`/base/updateClassDailyTea.do`,this.dailyAssessmentForm)
            .then(res=>{
              this.dailyAssessmentDialogVisible = false
              this.$message({
                type:'success',
                message:'编辑成功！'
              })
              this.loadDailyAssessment(this.page.currentPage,this.type)
            })
            .catch((err) => {
              this.$message({
                type: "info",
                message: err.message,
              });
            });
            return
          }
          
          let data = {
            name:this.dailyAssessmentAddForm.name,
            // check_time:this.dailyAssessmentForm.check_time,
            start_time:this.dailyAssessmentAddForm.start_time,
            end_time:this.dailyAssessmentAddForm.end_time,
            managerid:this.dailyAssessmentAddForm.managerid,
          }
          this.$post(`/base/insertClassDailyTea.do`,data)
          .then(res=>{
            this.dailyAssessmentDialogVisible = false
            
            this.$message({
              type:'success',
              message:'添加成功！'
            })
            this.loadDailyAssessment(1,this.type)
          })
          .catch(err=>{
            this.$message({
              type:'error',
              message:err.message
            })
          })

        }
      })
    
    },
    // 编辑日常考核
    editDailyAssessment(row){
      this.dailyAssessmentDialogVisible = true
      this.$nextTick(()=>{
        this.dailyAssessmentForm.id = row.id
        this.dailyAssessmentForm.name = row.name
        this.dailyAssessmentForm.managerid = row.managerid
        this.dailyAssessmentForm.check_time = row.check_time
      })
    },
    // 详情
    detailDailyAssessment(row){
      
      this.dailyAssessmentScoreDisabled = true
      this.dailyAssessmentScoreTableDialogVisible = true
      this.$post(`/base/queryClassDaily.do`,{daily_id:row.id})
      .then(res=>{
        this.scoreList = res.data
        
      })
    },
    // 删除
    deleteDailyAssessment(id){
      const that = this;
      this.$confirm("是否删除该条记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        this.$post("/base/deleteClassDailyTea.do", { id })
        .then((res) => {
          that.$message({
            type: "success",
            message: "删除成功!", 
          });
          that.loadDailyAssessment(that.page.currentPage,2);
        })
        .catch((err) => {
          that.$message.error(err.message)
        });
      })
      .catch(() => {
        this.$message({
        type: "info",
        message: "已取消删除",
        });
      });
    },
    // 得到日常考核班级
    getClass(id,check_time){
      this.$post(`/base/queryClassDaily.do`,{daily_id:id})
      .then(res=>{
        this.classList = res.data
        this.classListLength = this.classList.length

        this.scoreList = []
        for(let i = 0; i < this.classList.length; i++){
          let obj = {
            daily_id:id,
            check_time:check_time,
            classid:this.classList[i].classid, 
            cname:this.classList[i].cname,
            score1:this.classList[i].score1,
            score2:this.classList[i].score2,
            score3:this.classList[i].score3,
            score4:this.classList[i].score4,
            score5:this.classList[i].score5,
            total_score:this.classList[i].total_score,
            remark:this.classList[i].remark
          }
          this.scoreList.push(obj)
        } 
      })
    },
    // 当前日期
    currentDate(){
      let d = new Date()
      let year = d.getFullYear()
      let month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)
      let date = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
      return `${year}-${month}-${date}`
    },
    // 日常考核评分
    dailyAssessmentScore(row){
      let currentDate = this.currentDate()
      console.log(row)
      if(row.status == 2){
        this.dailyAssessmentScoreTableDialogVisible = true
        // 查询班级
        this.getClass(row.id,row.check_time)
        this.dailyAssessmentScoreDisabled = true
      }else{
        this.dailyAssessmentScoreDisabled = false

        this.dailyAssessmentScoreTableDialogVisible = true
        // 查询班级
        this.getClass(row.id,row.check_time)

        // if(currentDate == row.check_time){
        //   this.dailyAssessmentScoreTableDialogVisible = true
        //   // 查询班级
        //   this.getClass(row.id,row.check_time)
        // }else{
        //   this.$message({
        //     type:'info',
        //     message:'未到评分日期或评分日期已过！'
        //   })
        // }

      }
    
    },
    // 单选变化
    radioChange(index){
      this.scoreList[index].total_score = ''
      // 选择时
      this.scoreList.forEach((item,index)=>{
        item.total_score = (item.score1 + item.score2 + item.score3 + item.score4 + Number(item.score5)).toFixed(1)
      })
      
    },
    // 提交
    addDailyAssessmentScoreConfirm(){
      for(let i = 0; i < this.scoreList.length; i++){
        if(this.scoreList[i].score1 == null || this.scoreList[i].score2 == null || this.scoreList[i].score3 == null || this.scoreList[i].score4 == null || this.scoreList[i].score5 == null || this.scoreList[i].total_score == null){
          this.$message({
            type:'info',
            message:'请选择完所有！'
          })
          return
        }
      }
      // return
      this.$postJson(`/base/updateClassDaily.do`,this.scoreList)
      .then(res=>{
        this.dailyAssessmentScoreTableDialogVisible = false
        this.flag = true
        this.loadDailyAssessment(1,1)
        console.log(res)
      })
    },
  },
};
</script>

<style scoped lang="scss">
.form-height{
  height: 600px;
  overflow-x:auto;
}

.flex-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.input-box {
  width: 200px;
  margin-right: 5px;
}

.marginLeft {
  margin-left: 10px;
}

.picker{
  margin-left: 10px;
}

.center{
  
}

.center .title{
  text-align: center;
  font-size:20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.center td{
  padding:8px 0px;
  padding-left: 6px;
  text-align: center;
}

.center td /deep/ .el-input{
  width: 85px;
  margin: 0 6px;
}

.center .evaluationScoreStyle{
  padding: 2px 0px;
}

.center .evaluationScoreStyle .classCultureSummaryScoreStyle{
  width: 60px;
}

.evaluationScoreStyle .el-input /deep/ .el-input__inner{
  padding: 3px 3px;
}

/deep/ .el-radio{
  margin-right:4px!important;
}

/deep/ .el-radio .el-radio__input{
  // margin-left: 0px;
}

/deep/ .el-radio .el-radio__label{
  margin-left: -7px;
}

/deep/ .el-switch__label--left{
  margin-right: 4px;
}

/deep/ .el-switch__label--right{
  margin-left: 4px;
}



</style>
